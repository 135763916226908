import { Axis } from '@/consts/axis';

type SpacerProps = {
  size: number;
  axis: (typeof Axis)[keyof typeof Axis];
};

/** 縦方向と横方向の余白のみに責務を持つコンポーネント */
export const Spacer = ({ size, axis }: SpacerProps) => {
  const width = axis === Axis.VERTICAL ? 1 : size;
  const height = axis === Axis.HORIZONTAL ? 1 : size;

  const style = {
    display: 'block',
    width,
    minWidth: width,
    height,
    minHeight: height,
  };

  return <span style={style} />;
};
