import { useRouter } from 'next/router';

import { CV_SELF_PROMOTION_TEMPLATE } from '@/components/templates/cv/self-promotion-template/template-tab/common-template/const';
import { JOB_TYPE, type JobType as JobTypeKey } from '@/consts/job-type';
import { PATHS } from '@/consts/path';
import { RESUME_TITLE, type ResumeTitleKey } from '@/consts/resume-title';
import { useStorage } from '@/hooks/use-storage';
import { STORAGE_KEY } from '@/hooks/use-storage/type';

export const useSelectCv = () => {
  const { push } = useRouter();
  const { getStorage, setStorage } = useStorage();

  const setCvPreferences = (title: ResumeTitleKey, jobType: JobTypeKey) => {
    setStorage('selected-resume', RESUME_TITLE[title]);
    setStorage('browser-save', { checkedValue: 'true' });
    setStorage('terms-and-privacy', { termsCheckedValue: 'true' });
    setStorage('selected-job-type', JOB_TYPE[jobType]);
  };

  const initializeUserPreferences = async (resumeTitle: string, jobType: string): Promise<void> => {
    setStorage('selected-resume', resumeTitle);
    await push(PATHS.CV.PROFILE);
    setStorage('browser-save', { checkedValue: 'true' });
    setStorage('terms-and-privacy', { termsCheckedValue: 'true' });
    setStorage('selected-job-type', jobType);
    return;
  };

  const onSelectShokumuKeireki = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_RESUME_TITLE, JOB_TYPE.COMMON);
  const onSelectShokumuKeirekiSales = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_SALES_RESUME_TITLE, JOB_TYPE.SALES);
  const onSelectShokumuKeirekiEngineer = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_ENGINEER_RESUME_TITLE, JOB_TYPE.ENGINEER);
  const onSelectShokumuKeirekiAdministration = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_ADMINISTRATION_RESUME_TITLE, JOB_TYPE.ADMINISTRATION);
  const onSelectShokumuKeirekiMarketing = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_MARKETING_RESUME_TITLE, JOB_TYPE.MARKETING);
  const onSelectShokumuKeirekiService = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_SERVICE_RESUME_TITLE, JOB_TYPE.SERVICE);
  const onSelectShokumuKeirekiConsultant = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_CONSULTANT_RESUME_TITLE, JOB_TYPE.CONSULTANT);
  const onSelectShokumuKeirekiFinance = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_FINANCE_RESUME_TITLE, JOB_TYPE.FINANCE);
  const onSelectShokumuKeirekiEducation = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_EDUCATION_RESUME_TITLE, JOB_TYPE.EDUCATION);
  const onSelectShokumuKeirekiPR = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_PR_RESUME_TITLE, JOB_TYPE.PR);
  const onSelectShokumuKeirekiCreative = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_CREATIVE_RESUME_TITLE, JOB_TYPE.CREATIVE);
  const onSelectShokumuKeirekiManufacturing = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_MANUFACTURING_RESUME_TITLE, JOB_TYPE.MANUFACTURING);
  const onSelectShokumuKeirekiLogistics = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_LOGISTICS_RESUME_TITLE, JOB_TYPE.LOGISTICS);
  const onSelectShokumuKeirekiCustomerSupport = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_CUSTOMER_SUPPORT_RESUME_TITLE, JOB_TYPE.CUSTOMER_SUPPORT);
  const onSelectShokumuKeirekiLegal = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_LEGAL_RESUME_TITLE, JOB_TYPE.LEGAL);
  const onSelectShokumuKeirekiHR = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_HR_RESUME_TITLE, JOB_TYPE.HR);
  const onSelectShokumuKeirekiAccounting = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_ACCOUNTING_RESUME_TITLE, JOB_TYPE.ACCOUNTING);
  const onSelectShokumuKeirekiResearch = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_RESEARCH_RESUME_TITLE, JOB_TYPE.RESEARCH);
  const onSelectShokumuKeirekiRealEstate = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_REALESTATE_RESUME_TITLE, JOB_TYPE.REALESTATE);
  const onSelectShokumuKeirekiFood = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_FOOD_RESUME_TITLE, JOB_TYPE.FOOD);
  const onSelectShokumuKeirekiConstruction = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_CONSTRUCTION_RESUME_TITLE, JOB_TYPE.CONSTRUCTION);
  const onSelectShokumuKeirekiDriver = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_DRIVER_RESUME_TITLE, JOB_TYPE.DRIVER);
  const onSelectShokumuKeirekiCaregiver = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_CAREGIVER_RESUME_TITLE, JOB_TYPE.CAREGIVER);
  const onSelectShokumuKeirekiDentalHygienist = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_DENTAL_HYGIENIST_RESUME_TITLE, JOB_TYPE.DENTAL_HYGIENIST);
  const onSelectShokumuKeirekiChildcare = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_CHILDCARE_RESUME_TITLE, JOB_TYPE.CHILDCARE);
  const onSelectShokumuKeirekiNurse = async (): Promise<void> =>
    await initializeUserPreferences(RESUME_TITLE.CV_NURSE_RESUME_TITLE, JOB_TYPE.NURSE);

  type JobType = (typeof JOB_TYPE)[keyof typeof JOB_TYPE];
  const getJobType = () => getStorage<JobType>(STORAGE_KEY.SELECTED_CV_JOB_TYPE) || JOB_TYPE.COMMON;

  const getSelfPromotionTemplateByJobType = (jobType: JobType) =>
    CV_SELF_PROMOTION_TEMPLATE.filter((template) => template.jobType === jobType);

  return {
    /** jobtypeから職種別のテンプレートを取得 */
    getSelfPromotionTemplateByJobType,
    /** ローカルストレージからjobtypeを取得 */
    getJobType,
    /** 職務経歴書押下時のイベントハンドラ */
    onSelectShokumuKeireki,
    /** 職務経歴書_営業押下時のイベントハンドラ */
    onSelectShokumuKeirekiSales,
    /** 職務経歴書_エンジニア押下時のイベントハンドラ */
    onSelectShokumuKeirekiEngineer,
    /** 職務経歴書_事務職押下時のイベントハンドラ */
    onSelectShokumuKeirekiAdministration,
    /** 職務経歴書_マーケティング押下時のイベントハンドラ */
    onSelectShokumuKeirekiMarketing,
    /** 職務経歴書_サービス職押下時のイベントハンドラ */
    onSelectShokumuKeirekiService,
    /** 職務経歴書_コンサルタント押下時のイベントハンドラ */
    onSelectShokumuKeirekiConsultant,
    /** 職務経歴書_金融・証券関連職押下時のイベントハンドラ */
    onSelectShokumuKeirekiFinance,
    /** 職務経歴書_教育職押下時のイベントハンドラ */
    onSelectShokumuKeirekiEducation,
    /** 職務経歴書_広報・PR職押下時のイベントハンドラ */
    onSelectShokumuKeirekiPR,
    /** 職務経歴書_クリエイティブ職押下時のイベントハンドラ */
    onSelectShokumuKeirekiCreative,
    /** 職務経歴書_製造・生産管理職押下時のイベントハンドラ */
    onSelectShokumuKeirekiManufacturing,
    /** 職務経歴書_物流・運輸関連職押下時のイベントハンドラ */
    onSelectShokumuKeirekiLogistics,
    /** 職務経歴書_カスタマーサポート職押下時のイベントハンドラ */
    onSelectShokumuKeirekiCustomerSupport,
    /** 職務経歴書_法務・コンプライアンス職押下時のイベントハンドラ */
    onSelectShokumuKeirekiLegal,
    /** 職務経歴書_人事・採用関連職押下時のイベントハンドラ */
    onSelectShokumuKeirekiHR,
    /** 職務経歴書_経理・財務職押下時のイベントハンドラ */
    onSelectShokumuKeirekiAccounting,
    /** 職務経歴書_研究・開発職押下時のイベントハンドラ */
    onSelectShokumuKeirekiResearch,
    /** 職務経歴書_不動産関連職押下時のイベントハンドラ */
    onSelectShokumuKeirekiRealEstate,
    /** 職務経歴書_飲食業関連職押下時のイベントハンドラ */
    onSelectShokumuKeirekiFood,
    /** 職務経歴書_施工管理職押下時のイベントハンドラ */
    onSelectShokumuKeirekiConstruction,
    /** 職務経歴書_ドライバー職押下時のイベントハンドラ */
    onSelectShokumuKeirekiDriver,
    /** 職務経歴書_介護職押下時のイベントハンドラ */
    onSelectShokumuKeirekiCaregiver,
    /** 職務経歴書_歯科衛生士押下時のイベントハンドラ */
    onSelectShokumuKeirekiDentalHygienist,
    /** 職務経歴書_保育士押下時のイベントハンドラ */
    onSelectShokumuKeirekiChildcare,
    /** 職務経歴書_看護師押下時のイベントハンドラ */
    onSelectShokumuKeirekiNurse,
    /** local storageに職務経歴の値を保存するための処理 */
    setCvPreferences,
  };
};
